
/**
 *
 * @param submittedForm
 * @param {jqxhr} jqXHR
 * @param {function} successCallback
 */
window.checkRecaptcha = function (submittedForm, jqXHR, successCallback) {
    var responseJSON = JSON.parse(jqXHR.responseText);

    if (responseJSON.response === "success" || responseJSON.message) {

        successCallback();

        submittedForm.find('[name="recaptcha_type"]').val('v3');
        resetFormRecaptcha(submittedForm);
    } else {
        if (responseJSON.version === "v3") {
            submittedForm.find('[name="recaptcha_type"]').val('v2');

            resetRecaptchaDeep(submittedForm);
            submittedForm.find('.g-recaptcha-wrap').addClass('g-recaptcha-wrap_v2-active');
        } else {
            if (responseJSON.response === 'empty') {
                submittedForm.find('.g-recaptcha-wrap__error').text('Заполните рекапчу');
            } else {
                window.resetAllRecaptcha();
                submittedForm.find('.g-recaptcha-wrap__error').text('Отправка формы не удалась, напишите нам на почту inbox@elma365.com');
            }
        }
    }
}

/**
 * Reset form with recaptcha
 * @param form
 */
window.resetFormRecaptcha = function (form) {
    form.find('[name="recaptcha_type"]').val('v3');

    window.resetAllRecaptcha();
    form.trigger("reset");
    form.find('.g-recaptcha-wrap').removeClass('g-recaptcha-wrap_v2-active');
    form.find('.g-recaptcha-wrap__error').text('');
};


/**
 * Recaptcha v2 deep reset
 * @param form
 */
window.resetRecaptchaDeep = function (form) {
    window.resetAllRecaptcha();
    form.find('.g-recaptcha-wrap__error').text('');
};

