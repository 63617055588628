// GET URL PARAM

var getUrlParameter = function getUrlParameter(sParam) {
	var sPageURL = window.location.search.substring(1),
		sURLVariables = sPageURL.split('&'),
		sParameterName,
		i;

	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');

		if (sParameterName[0] === sParam) {
			return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
		}
	}
};

// CHECK URL PARAM

if (getUrlParameter('utm_source')) {
	$.cookie('utm_source', getUrlParameter('utm_source'), {
		path: '/'
	});
}
if (getUrlParameter('utm_medium')) {
	$.cookie('utm_medium', getUrlParameter('utm_medium'), {
		path: '/'
	});
}
if (getUrlParameter('utm_campaign')) {
	$.cookie('utm_campaign', getUrlParameter('utm_campaign'), {
		path: '/'
	});
}
if (getUrlParameter('referer_url')) {
	$.cookie('referer_url', getUrlParameter('referer_url'), {
		path: '/'
	});
}



$('form:not([action="/search/"]) input').on('click', function () {
	$(this)[0].setCustomValidity('');
});

function setCookie(key, value) {
	var expires = new Date();
	expires.setTime(expires.getTime() + (1 * 24 * 60 * 60 * 1000));
	document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
}

function getCookie(key) {
	var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
	return keyValue ? keyValue[2] : null;
}

var url = window.location;
var urlAux = url.pathname.split('/');
var caseBool = false;
var currentCase;

// Get current case by url and save to variable currentCase. ex: test.com/case/quick.html => quick

for (urlElementItem in urlAux) {

	if (urlAux[urlElementItem] == 'case' && caseBool == false) {
		caseBool = true
	} else if (caseBool && urlAux[urlElementItem].includes('.html')) {
		currentCase = urlAux[urlElementItem].slice(0, -5)
	}
}

/*$('form[data-validate="true"]').each(function () {
	var formObject = $(this);


	$(this).submit(function (e) {
		var formData = new FormData(this);
		var _this = this;

		if (this.track != null) {
			fbq('track', this.track.value);
		}
		ga(function (tracker) {
			formData.push({
				name: "ga_client_Id",
				value: tracker.get('clientId')
			});
		});

		// SEND DATA TO app/modules/Formhandler/controllers/IndexController.php
		$.ajax({
			method: "POST",
			url: _this.getAttribute('action'),
			contentType: false,
			processData: false,
			context: document.body,
			data: formData
		}).done(function (data) {
			setCookie(currentCase, true);

			data = JSON.parse(data);
			message = data.message;


			if ($(_this).closest('.lock').length > 0) {
				$(_this).closest('.lock').toggleClass('lock');
			} else {
				if(message === 'error') {
					data.fields.forEach(function (elem) {
						$(_this).find('input[name= ' + elem + ']').addClass('error');
						$(_this).find('select[name= ' + elem + ']').addClass('error');
						$(_this).find('input[name= ' + elem + ']').change(function () {
							this.classList.remove('error');
						});
						$(_this).find('select[name= ' + elem + ']').change(function () {
							this.classList.remove('error');
						});
					});

				} else {
					$(_this).html(message);
					//console.log('Мне оправили POST запрос ' + message);
				}
			}

			if (data.cookie) {
				for (el in data.cookie) {
					$.cookie(el, data.cookie[el], {
						expires: 365
					})
				}
			}
			ym(109550, 'reachGoal', data.form.action);
			ga('send', 'event', 'form', data.form.action);
			fbq('track', 'Lead');
		}).fail(function (jqXHR, textStatus, errorThrown) {
			if (jqXHR.status == 503) {
				data = JSON.parse(jqXHR.responseText);
				field = $(_this).find("[name='" + data.field + "']")[0];
				field.setCustomValidity(data.message);
				field.reportValidity();
			} else {
				$(_this).html('Ошибка при отправке <b>' + jqXHR.status + '</b>, обновите страницу и попробуйте снова');
			}
		}).always(function () {});
		return false;
	})
});*/

$('form:not([action="/search/"])').each(function () {
    var formObject = $(this);
    $(this).submit(function (e) {
        if ($(this).hasClass('try-popup__form')) {
            return false;
        }
        e.preventDefault();
        //			var formData =  $( this ).serializeArray();
        var formWrap = $('.case-demonstration__wrap-content');
        var formData = new FormData(this);
        var _this = this;
        var $form = $(this);

        if (this.track != null) {
            fbq('track', this.track.value);
        }
        ga(function (tracker) {
            formData.push({
                name: "ga_client_Id",
                value: tracker.get('clientId')
            });
        });

        if (formObject.attr('id') == 'form-video') {
            $('#button-video_open').disabled;

            var data = {};
            $('#form-video').find('input, textearea, select').each(function() {
                data[this.name] = $(this).val();
            });

            $.post('/webinars/saveLeadCreateCookies/', {
                data: data,
            }, function (data) {
                if (data) {
                    $('#video-vebinar').show();
                    $('#banner-for_video').hide();
                }
            });
        } else {
            // SEND DATA TO app/modules/Formhandler/controllers/IndexController.php
            formData.set('recaptcha_response_v3', $form.find('[name="recaptcha_response"]').val());
            formData.set('recaptcha_response_v2', $form.find('[name="g-recaptcha-response"]').val());
            $.ajax({
                method: "POST",
                url: "/formhandler/",
                contentType: false,
                processData: false,
                context: document.body,
                data: formData,
                beforeSend: function() {
                    $(_this).find('.submit-spinner').addClass('submit-spinner_active');
                },
                complete: function() {
                    $(_this).find('.submit-spinner').removeClass('submit-spinner_active');
                }
            }).done(function (data, statusText, jqXHR) {
                setCookie(currentCase, true);
                if (formObject.find('.case-demonstration__wrap-video').data('youtube') || $("input[name='youtube']", formObject).val() == "true") {
                    var tag = document.createElement('script');
                    tag.src = "https://www.youtube.com/iframe_api";
                    var firstScriptTag = document.getElementsByTagName('script')[0];
                    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                    var player;

                    var done = false;

                    var modalAction = $("input[name='action']", formObject).val()
                    if (!$("input[name='youtube']", formObject).val() == "true") {
                        var youtubeVideoId = formObject.find('.case-demonstration__wrap-video').attr('data-youtube')
                    } else {
                        var youtubeVideoId = $('.case-demonstration__wrap-video').data('youtube')
                    }

                    $('.player').each(function () {
                        player = new YT.Player(this, {
                            height: '100%',
                            width: '100%',
                            videoId: youtubeVideoId,
                        });

                    });
                    var youtubeInput = $("input[name='youtube']", formObject).val();
                    formWrap.attr('data-success', 'true');
                }
                if ($("input[name='youtube']", formObject).val() == "true") {
                    formObject.html('<div class="downloads-demo__form-wrap w-100 mb-3">\n' +
                        '<p style="margin-bottom: 11px;">Свяжитесь с нами, чтобы задать\n' +
                        'вопросы о решении или получить \n' +
                        'готовый демостенд системы.\n' +
                        '</p>\n' +
                        '    <div class="p-0">\n' +
                        '        <a href="tel:+73412936693" class="d-inline-block mb-1">+7 (3412) 93-66-93</a>\n' +
                        '    </div>\n' +
                        '    <div class="p-0">\n' +
                        '    <a href="mailto:elma@elewise.ru" class="d-inline-block mb-1">elma@elewise.ru</a>\n' +
                        '    </div>\n' +
                        '       </div>');

                }
                formWrap.addClass('active');
                try {
                    var modalAction = $("input[name='action']").val()
                    data = JSON.parse(data);
                    message = data.message;

                    if ($(_this).closest('.lock').length > 0) {
                        $(_this).closest('.lock').toggleClass('lock');
                    } else {
                        if (formObject.find('.case-demonstration__wrap-video').data('youtube') || youtubeInput == "true") {
                            $('.downloads-demo__form').each(function () {
                                if ($('.case-demonstration__wrap-content').attr('data-success') === 'true') {
                                    $(this).html('<p>Success</p>')
                                }
                                $(this).html('<div class="downloads-demo__form-wrap w-100 mb-3">\n' +
                                    '<p style="margin-bottom: 11px;">Свяжитесь с нами, чтобы задать\n' +
                                    'вопросы о решении или получить \n' +
                                    'готовый демостенд системы.\n' +
                                    '</p>\n' +
                                    '    <div class="p-0">\n' +
                                    '        <a href="tel:+73412936693" class="d-inline-block mb-1">+7 (3412) 93-66-93</a>\n' +
                                    '    </div>\n' +
                                    '    <div class="p-0">\n' +
                                    '    <a href="mailto:elma@elewise.ru" class="d-inline-block mb-1">elma@elewise.ru</a>\n' +
                                    '    </div>\n' +
                                    '       </div>');
                            })
                        } else {
                            $(_this).html(message);
                        }
                    }

                    if ($form.hasClass('try-popup__form_captcha')) {
                        window.checkRecaptcha($form, jqXHR, function (){
                            $form.html(message);
                        });

                        return true;
                    }

                    if (data.cookie) {
                        for (el in data.cookie) {
                            $.cookie(el, data.cookie[el], {
                                expires: 365
                            })
                        }
                    }
                    var action = (typeof(data.form) != "undefined" && typeof(data.form.action) != "undefined") ? data.form.action : modalAction;
                    ym(109550, 'reachGoal', action);
                    ga('send', 'event', 'form', action);
                    fbq('track', 'Lead');

                    if (formObject.find('.case-demonstration__wrap-video').data('youtube') || youtubeInput == "true") {
                    } else {
                        if (data.reload == true) {
                            location.reload();
                        }
                    }
                } catch (e) {
                    $(_this).html(e.message);
                }
            }).fail(function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.status === 503) {
                    //data = JSON.parse(jqXHR.responseText);
                    //field = $(_this).find("[name='" + data.field + "']")[0];
                    //field.setCustomValidity(data.message);
                    //field.reportValidity();
                    alert('Превышен лимит запросов, отправка произойдет автоматически через 35 секунд');
                    setTimeout(function () {
                        $(_this).find('button').trigger('click')
                    }, 35000);
                }
            }).always(function () {
            });
        }
        return false;
    })
});
