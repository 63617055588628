'use strict';

(function () {
    var MOBILE_WIDTH = 767;
    var widthViewport  = document.documentElement.offsetWidth;
    var listNav = document.querySelector('.second-mobile-nav__list');
    var activeLink = document.querySelector('.second-mobile-nav__link--active');
    var loadId = document.querySelector('#widgit-nav');

    if (loadId) {
        var addNameLink  = function () {
            if (widthViewport < MOBILE_WIDTH) {
                var elem = listNav.querySelector('.second-nav__link--active');

                activeLink.textContent = '' + elem.text + '';
                elem.classList.add('d-none');
            }
        };

        addNameLink();
    }
} ());
