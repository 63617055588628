$('.dd-item__switcher').click(function(){
    $(this).parents('.dd-item').toggleClass('dd-item_active');
});

$('.dd-item input').on('change', function(){
    var value = $(this).siblings('.dd-item__select-text').text();

    $(this).parents('.dd-item').find('.dd-item__value').text(value);
    $(this).parents('.dd-item').removeClass('dd-item_active');
});

$(document).mouseup(function (e){
    var div = $(".dd-item_active");

    if (!div.is(e.target) && div.has(e.target).length === 0) {
        $('.dd-item').removeClass('dd-item_active');
    }
});

$('.dd-item').each(function(){
    var checkedElement = $(this).find('input:checked'),
        checkedValue = checkedElement.siblings('.dd-item__select-text').text();

    $(this).find('.dd-item__value').text(checkedValue);
});

$(document).ready(function(){
    $('.scrollbar-inner').scrollbar();
});