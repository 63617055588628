if(document.querySelector('.youtube__wrap')) {
    var layers = document.querySelectorAll('.youtube__wrap');

    layers.forEach(function (elem) {
        init(elem);
    });

    function init(elem) {
        var img = getImage(elem.getAttribute('data-id-video'));
        var image = new Image();
        image.onerror = function(){
            elem.setAttribute('data-img', 'false');
        };
        image.addEventListener('load', function(){
            elem.style.backgroundImage = 'url(' + img + ')';
        });
        image.src = img;
        elem.addEventListener('click', function () {
            var frame = this.querySelector('iframe');
            this.style.backgroundImage = 'none';
            this.classList.add('hidden');
            frame.setAttribute('src', 'https://www.youtube.com/embed/' + this.getAttribute('data-id-video') + '?autoplay=1');
        });

        elem.addEventListener('mouseleave', function() {
            elem.querySelector(".youtube__button-play").setAttribute('src', '/image/youtube_play.png');
        });
        elem.addEventListener('mouseenter', function() {
            elem.querySelector(".youtube__button-play").setAttribute('src', '/image/youtube_play_hover.png');
        });
    }

    function getImage (id) {
        return 'https://img.youtube.com/vi/' + id + '/hqdefault.jpg';
    }
}
