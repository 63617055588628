var $videoSrc;
$('.video-popup').click(function() {
    $videoSrc = $(this).data( "src" );
});

$(document).on('show.bs.modal','#video-modal', function () {
    $("#video-in-popup").attr('src', $videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0" );
});

$(document).on('hide.bs.modal','#video-modal', function () {
    $("#video-in-popup").attr('src',$videoSrc);
});