var fadeHeaderElement;

if (window.matchMedia("(max-width: 1085px)").matches) {
    fadeHeaderElement = $('.header__logo');
} else {
    fadeHeaderElement = $('.header__nav');
}

$('.header__search-switcher').click(function (){
    fadeHeaderElement.fadeOut(200);
    setTimeout(function (){
        $('.header').addClass('header_open-search');
        $('.search__input').focus();
    }, 200);
});

$(document).mouseup(function (e) {
    var searchWrap = $('.header__search-wrap');
    if (!searchWrap.is(e.target)
        &&
        searchWrap.has(e.target).length === 0) {

        if($('.header').hasClass('header_open-search')) {
            $('.header').removeClass('header_open-search');
            fadeHeaderElement.fadeIn(200);
        }
    }
});