'use strict';

(function () {
    var searchContainer = document.querySelector('.zooming-image');
    if (searchContainer) {
        var images = searchContainer.querySelectorAll('img');
        var popup = document.querySelector('#zoomImage');
        var insertImage = popup.querySelector('img');

        var setPopupAttribute = function () {
            for (var i = 0; i < images.length; i++) {
                if (images[i].naturalWidth >  650 && images[i].getAttribute('data-target') !== "#noZoom") {
                    images[i].setAttribute('data-toggle', 'modal');
                    images[i].setAttribute('data-target', '#zoomImage');
                    images[i].style.cursor = 'zoom-in';
                } else {
                    continue;
                }
            }
        };

        var zoomImage = function (evt) {
            var target = evt.target;
            for (var i = 0; i < images.length; i++) {
                if (target === images[i]) {
                    var getParameters = {
                        src: images[i].getAttribute('src'),
                        alt: images[i].getAttribute('alt'),
                    };

                    insertImage.src = getParameters.src;
                    insertImage.alt = getParameters.alt;
                    insertImage.style.maxWidth = '100%';
                    //insertImage.style.maxHeight = '70vh';
                    insertImage.style.boxSizing = 'border-box';
                    break;
                }
            }
        };

        var resetZoom = function () {
            var fancys = document.querySelectorAll('.fancy');
            fancys.forEach(function (elem) {
                elem.removeAttribute('href');
            });

        };
        resetZoom();
        setPopupAttribute();
        searchContainer.addEventListener('click', zoomImage);
    }
}());

