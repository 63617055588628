"use strict";

(function () {
    var MOBILE_WIDTH = 767;
    var menuList = document.querySelector('.second-mobile-nav__list');
    var activeLink = document.querySelector('.second-mobile-nav__link--active');
    var links = document.querySelectorAll('.second-mobile-nav__link');
    var items = document.querySelectorAll('.second-mobile-nav__item');
    var widthViewport  = document.documentElement.offsetWidth;


    var actionMobileMenu = function () {
        menuList.classList.toggle('second-mobile-nav__list--closed');
    };

    var clearDisplayNone = function () {
        var elem = menuList.querySelector('.d-none');
        elem.classList.remove('d-none');
    };

    var actionMobileElement = function (evt) {
        var target = evt.target;

        if (target !== activeLink) {
            clearDisplayNone();
            for (var i = 0; i < links.length; i++) {
                if (target === links[i] || target === items[i]) {
                    activeLink.textContent = '' + links[i].text + '';
                    items[i].classList.add('d-none');
                    menuList.classList.add('second-mobile-nav__list--closed');
                    break;
                }
            }
        } else if (target === activeLink) {
            actionMobileMenu();
        }

    };

    if (menuList && widthViewport < MOBILE_WIDTH) {
        menuList.addEventListener('click', actionMobileElement);
    }
} ());
