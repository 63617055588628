var limitHeight = 50;
var header = document.querySelector('.header');

document.addEventListener('scroll', function () {
    var position = document.documentElement.scrollTop;

    if(position > limitHeight) {
        header.classList.add('active');
    } else {
        header.classList.remove('active');
    }
});
