/** отслеживание заполнения полей **/
function listenFillInputs() {
    $(".fill_listener").focus(function(){
        $(this).parent().addClass('focused');
    });
    $(".fill_listener").blur(function(){
        $(this).parent().removeClass('focused');
    });
    $(".fill_listener").change(function(){
        if ($(this).val().length) {
            $(this).parent().addClass('filled');
        } else {
            $(this).parent().removeClass('filled');
        }
    });
}


listenFillInputs();
