$(function() {
    $('.try-popup__form').on('submit', function (e) {
        e.preventDefault();

        var $form = $(this);
        var thxText = $form.find('.try-popup__hide-thx-text').html();
        var popupMainWrap = $form.parents('.try-popup__main');
        var popupThxText = popupMainWrap.find('.try-popup__thx-text');
        var data = $form.serializeArray();
        data.push({
            name: 'recaptcha_response_v3',
            value: $form.find('[name="recaptcha_response"]').val()
        })
        data.push({
            name: ' recaptcha_response_v2',
            value: $form.find('[name="g-recaptcha-response"]').val()
        });

        $.ajax({
            type: "POST",
            url: $form.attr('action'),
            data: data,
            beforeSend: function() {
                $form.find('.submit-spinner').addClass('submit-spinner_active');
            },
            complete: function(jqXHR) {
                $form.find('.submit-spinner').removeClass('submit-spinner_active');

                switch (jqXHR.status) {
                    case 200:
                        if ($form.hasClass('try-popup__form_captcha')) {
                            window.checkRecaptcha($form, jqXHR, function (){
                                popupThxText.html(thxText);
                                popupMainWrap.addClass('try-popup__main_thx');

                                setTimeout(function () {
                                    popupMainWrap.removeClass('try-popup__main_thx');
                                }, 7000);
                            });
                        } else {
                            popupThxText.html(thxText);
                            popupMainWrap.addClass('try-popup__main_thx');

                            setTimeout(function () {
                                popupMainWrap.removeClass('try-popup__main_thx');
                                $form[0].reset();
                            }, 7000);

                            if($form.hasClass('try-popup__form_rpa')) {
                                window.open('https://elma-rpa.ai/ru/download/ELMA_RPA_Designer.exe/', '_blank');
                            }
                        }

                        break;
                    case 503:
                        alert('Превышен лимит запросов, отправка произойдет автоматически через 35 секунд');
                        setTimeout(function () {
                            $form.find('button').trigger('click')
                        }, 35000);

                        break;
                    default:
                        popupThxText.html('На сервере ошибка');
                        popupMainWrap.addClass('try-popup__main_thx');

                        setTimeout(function () {
                            popupMainWrap.removeClass('try-popup__main_thx');

                            window.resetFormRecaptcha($form);
                        }, 7000);
                }
            }
        });
    });
});
